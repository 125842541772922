<template>
	<div>
		<PageLayout :title="$t('dashboard.wallet')">
			<div class="wallet-picker">
				<div
					:class="form.isDemoWallet ? 'wallet-picker__not-selected' : 'wallet-picker__selected'"
					@click="setIsDemoWallet(false)"
				>
					<img class="icon__image" :src="require('../../assets/real-account.svg')" />
					{{ $t('wallets.real-account') }}
				</div>
				<div
					:class="form.isDemoWallet ? 'wallet-picker__selected' : 'wallet-picker__not-selected'"
					@click="setIsDemoWallet(true)"
				>
					<img class="icon__image" :src="require('../../assets/demo-account.svg')" />
					{{ $t('wallets.demo-account') }}
				</div>
			</div>
			<div class="wallet-form">
				<div class="small-width">
					<CForm @submit.prevent="submitForm">
						<MaterialSelect
							id="currency"
							v-model="form.currency.value"
							:label="$t('wallets.currency')"
							:error="form.currency.error"
						>
							<option v-for="(currency, currencyIndex) in currencies" :key="currencyIndex" :value="currency">
								{{ currency }}
							</option>
						</MaterialSelect>

						<MaterialSelect
							id="leverage"
							v-model="form.leverage.value"
							:label="$t('wallets.leverage')"
							:error="form.leverage.error"
						>
							<option v-for="(leverage, leverageIndex) in leverages" :key="leverageIndex" :value="leverage">
								{{ leverage }}
							</option>
						</MaterialSelect>

						<CButton type="submit" primary :success="showSuccess" :loading="loading">
							{{ $t('actions.create') }}
						</CButton>

						<div>
							<span class="error">{{ error }}</span>
						</div>
					</CForm>
				</div>
			</div>
		</PageLayout>
	</div>
</template>

<script>
import apiClient from '../../api';
import PageLayout from '@/components/page-layout/PageLayout';
import MaterialSelect from '@/components/material-select/MaterialSelect';
import CForm from '@/shared/cform/CForm';
import CButton from '@/shared/cbutton/CButton';

export default {
	name: 'Wallets',
	components: {
		PageLayout,
		MaterialSelect,
		CForm,
		CButton,
	},
	data() {
		return {
			form: {
				currency: {
					error: null,
					value: 'USD',
				},
				leverage: {
					error: null,
					value: null,
				},
				isDemoWallet: false,
			},
			showSuccess: false,
			leverages: [],
			currencies: ['USD'],
			loading: false,
			error: null,
		};
	},
	async beforeMount() {
		await this.getSelectableLeverages();
	},
	methods: {
		async getSelectableLeverages() {
			try {
				const { leverages } = await apiClient.getSelectableLeverages();
				this.leverages = leverages;
			} catch (e) {
				if (e.response.data.error) {
					this.form.leverage.error = e.response.data.error.message;
				}
				if (e.response.statusText) {
					this.form.leverage.error = e.response.statusText;
				}
			}
		},
		setIsDemoWallet(checked) {
			this.form.isDemoWallet = checked;
		},
		async submitForm() {
			const leverage = this.form.leverage.value;
			const currency = this.form.currency.value;
			const isDemoWallet = this.form.isDemoWallet;
			this.loading = true;
			this.error = null;

			try {
				await apiClient.createWallet(currency, leverage, isDemoWallet);
				this.loading = false;
				this.showSuccess = true;
				setTimeout(() => (this.showSuccess = false), 3500);
			} catch (e) {
				this.loading = false;
				if (e?.response?.data?.error?.message) {
					this.error = e?.response?.data?.error?.message;
				}

				if (e?.response?.data?.errors) {
					for (const error of e.response.data.errors) {
						const { param, msg } = error;
						this.form[param].error = msg;
					}
				}
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.wallet-form {
	background: #1b1d21;
	border: 1px solid #2a2e31;
	border-radius: 10px;
	margin-top: 2%;
	padding-top: 30px;
	padding-bottom: 30px;
}

.wallet-picker {
	display: flex;
	justify-content: space-between;

	&__not-selected {
		width: 49%;
		background: #1b1d21;
		border: 1px solid #2a2e31;
		border-radius: 10px;
		height: 138px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}

	&__selected {
		width: 49%;
		background: linear-gradient(134.22deg, #565a5c -63.52%, #2a2e31 74.66%);
		border-radius: 10px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
}
</style>
